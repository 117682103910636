import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const User = () => import('@/views/user/listuser')
const TambahUser = () => import('@/views/user/tambahuser')
const EditUser = () => import('@/views/user/edituser')

// const Jalan = () => import('@/views/jalan/listjalan')
// const TambahJalan = () => import('@/views/jalan/tambahjalan')
// const EditJalan = () => import('@/views/jalan/editjalan')

// const Rambu = () => import('@/views/rambu/listrambu')
// const TambahRambu = () => import('@/views/rambu/tambahrambu')
// const EditRambu = () => import('@/views/rambu/editrambu')

// const Simpang = () => import('@/views/simpang/listsimpang')
// const TambahSimpang = () => import('@/views/simpang/tambahsimpang')
// const EditSimpang = () => import('@/views/simpang/editsimpang')

// const Hambatansamping = () => import('@/views/hambatansamping/listhambatansamping')
// const TambahHambatansamping = () => import('@/views/hambatansamping/tambahhambatansamping')
// const EditHambatansamping = () => import('@/views/hambatansamping/edithambatansamping')

// const Penanaman = () => import('@/views/penanaman/listpenanaman')
// const TambahPenanaman = () => import('@/views/penanaman/tambahpenanaman')
// const EditPenanaman = () => import('@/views/penanaman/editpenanaman')
// const DetailPenanaman = () => import('@/views/penanaman/detailpenanaman')

const Layer = () => import('@/views/layer/listlayer')
const TambahLayer = () => import('@/views/layer/tambahlayer')
const EditLayer = () => import('@/views/layer/editlayer')

const Tabular = () => import('@/views/tabular/listtabular')
const TambahTabular = () => import('@/views/tabular/tambahtabular')
const EditTabular = () => import('@/views/tabular/edittabular')

const Dokumen = () => import('@/views/dokumen/listdokumen')
const TambahDokumen = () => import('@/views/dokumen/tambahdokumen')
const EditDokumen = () => import('@/views/dokumen/editdokumen')

const Berita = () => import('@/views/berita/listberita')
const TambahBerita = () => import('@/views/berita/tambahberita')
const EditBerita = () => import('@/views/berita/editberita')

const Galeri = () => import('@/views/galeri/listgaleri')
const TambahGaleri = () => import('@/views/galeri/tambahgaleri')
const EditGaleri = () => import('@/views/galeri/editgaleri')

const Kategori = () => import('@/views/kategori/listkategori')
const TambahKategori = () => import('@/views/kategori/tambahkategori')
const EditKategori = () => import('@/views/kategori/editkategori')

const Profilku = () => import('@/views/profilku/profilku')
const GantiPassword = () => import('@/views/ubahpassword/ubahpassword')

const Pos = () => import('@/views/Pos')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

Vue.use(Router)
let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'pos',
          name: 'Pos',
          component: Pos,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'Profilku',
          name: 'profilku',
          component: Profilku,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'gantipassword',
          name: 'GantiPassword',
          component: GantiPassword,
          meta: {
            requiresAuth: true
          }
        },
        // {
        //   path: 'jalan',
        //   redirect: '/jalan/list',
        //   name: 'Jalan',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'List Jalan',
        //       component: Jalan,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'add',
        //       name: 'Add Jalan',
        //       component: TambahJalan,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'edit/:id_jalan',
        //       name: 'Edit Jalan',
        //       component: EditJalan,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //   ]
        // },
        // {
        //   path: 'rambu',
        //   redirect: '/rambu/list',
        //   name: 'Rambu',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'List Rambu',
        //       component: Rambu,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'add',
        //       name: 'Add Rambu',
        //       component: TambahRambu,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'edit/:id_rambu',
        //       name: 'Edit Rambu',
        //       component: EditRambu,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //   ]
        // },
        
        // {
        //   path: 'hambatansamping',
        //   redirect: '/hambatansamping/list',
        //   name: 'Hambatan Samping',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'List Hambatan Samping',
        //       component: Hambatansamping,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'add',
        //       name: 'Add Hambatan Samping',
        //       component: TambahHambatansamping,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'edit/:id_hambatan_samping',
        //       name: 'Edit Hambatan Samping',
        //       component: EditHambatansamping,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //   ]
        // },
        // {
        //   path: 'simpang',
        //   redirect: '/simpang/list',
        //   name: 'Simpang',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'List Simpang',
        //       component: Simpang,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'add',
        //       name: 'Add Simpang',
        //       component: TambahSimpang,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'edit/:id_simpang',
        //       name: 'Edit Simpang',
        //       component: EditSimpang,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //   ]
        // },
        // {
        //   path: 'penanaman',
        //   redirect: '/penanaman/list',
        //   name: 'Penanaman',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'List Penanaman',
        //       component: Penanaman,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'add',
        //       name: 'Add Penanaman',
        //       component: TambahPenanaman,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'edit/:id_detail_penanaman',
        //       name: 'Edit Penanaman',
        //       component: EditPenanaman,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       path: 'detail/:id_penanaman',
        //       name: 'Detail Penanaman',
        //       component: DetailPenanaman,
        //       meta: {
        //         requiresAuth: true
        //       }
        //     },
        //   ]
        // },
        {
          path: 'layer',
          redirect: '/layer/list',
          name: 'Layer',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Layer',
              component: Layer,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Layer',
              component: TambahLayer,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_layer',
              name: 'Edit Layer',
              component: EditLayer,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },

        {
          path: 'tabular',
          redirect: '/tabular/list',
          name: 'Tabular',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Tabular',
              component: Tabular,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Tabular',
              component: TambahTabular,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_tabular',
              name: 'Edit Tabular',
              component: EditTabular,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        
        {
          path: 'dokumen',
          redirect: '/dokumen/list',
          name: 'Dokumen',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Dokumen',
              component: Dokumen,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Dokumen',
              component: TambahDokumen,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_dokumen',
              name: 'Edit Dokumen',
              component: EditDokumen,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },

        {
          path: 'berita',
          redirect: '/berita/list',
          name: 'Berita',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Berita',
              component: Berita,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Berita',
              component: TambahBerita,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_berita',
              name: 'Edit Berita',
              component: EditBerita,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },

        {
          path: 'galeri',
          redirect: '/galeri/list',
          name: 'Galeri',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Galeri',
              component: Galeri,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Galeri',
              component: TambahGaleri,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_galeri',
              name: 'Edit Galeri',
              component: EditGaleri,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },

        {
          path: 'kategori',
          redirect: '/kategori/list',
          name: 'Kategori',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Kategori',
              component: Kategori,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Kategori',
              component: TambahKategori,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_kategori',
              name: 'Edit Kategori',
              component: EditKategori,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        
        {
          path: 'user',
          redirect: '/user/list',
          name: 'User',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List User',
              component: User,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add User',
              component: TambahUser,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_user',
              name: 'Edit User',
              component: EditUser,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        guest: true
      }
    },
    // {
    //   path: '/pages',
    //   redirect: '/pages/404',
    //   name: 'Pages',
    //   component: {
    //     render(c) { return c('router-view') }
    //   },
    //   children: [
    //     {
    //       path: '404',
    //       name: 'Page404',
    //       component: Page404
    //     },
    //     {
    //       path: '500',
    //       name: 'Page500',
    //       component: Page500
    //     },
    //   ]
    // }
  ]
}

// Meta Handling


// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') === '') {
//       next({
//         path: '/login'
//       })
//     } else {
//       let user = JSON.parse(localStorage.getItem('user'))
//       // alert('dashbord ' + localStorage.getItem('jwt'));
//       if (to.matched.some(record => record.meta.is_admin)) {
//         next({ path: '/dashboard' })
//       } else {
//         next()
//       }
//     }
//   } else if (to.matched.some(record => record.meta.guest)) {
//     if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') == '') {
//       next({
//         path: '/login'
//       })
//     } else {
//       // alert('dashbord ' + localStorage.getItem('jwt'));
//       next({ path: '/dashboard' })

//     }
//   } else {
//     next()
//   }
// })

const redirectToLogin = route => {
  const LOGIN = 'Login';
  if (route.name != LOGIN) {
    return { name: LOGIN, replace: true, query: { redirectFrom: route.fullPath } };
  }
};

const redirectToHome = route => {
  const DEFAULT = 'Dashboard';
  return { name: DEFAULT, replace: true };
};

const DEFAULT_TITLE = 'Sistem Informasi Geografis Perkim KALBAR';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE;
  });
});

router.beforeEach((to, from, next) => {
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') == "") {
  //     router.push({ path: '/login' });
  //   } else {
  //     let user = JSON.parse(localStorage.getItem('user'))
  //     if (user == null) {
  //       if (user.level == 1) {
  //         router.push({ path: '/dashoard' });
  //       } else {
  //         // next({ path: '/dashboard' })
  //         router.push({ path: '/dashoard' });
  //       }
  //     } else {
  //       router.push({ path: '/login' });
  //     }
  //   }
  // } else if (to.matched.some(record => record.meta.guest)) {
  //   if (localStorage.getItem('jwt') == null) {
  //     router.push({ path: '/login' });
  //   } else {
  //     next({ path: '/dashboard' })
  //   }
  // } else {
  //   router.push({ path: '/login' });
  // }

  const userIsAuthenticated = localStorage.getItem('jwt');
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  // if (localStorage.getItem('jwt') == null) {
  //   router.push({ path: '/login' });
  // } else {
  //   next({ path: '/dashboard' })
  // }
  // if (!userIsAuthenticated && to.fullPath === '/') {
  //   alert('ERROR1');
  //   return redirectToLogin(to);
  // }
  // alert(to.path);
  if (to.path == '/register') {
    next();
  }
  else if (to.path !== '/login') {
    if (requiresAuth) {
      if (!userIsAuthenticated) {
        alert('Maaf! Anda harus login terlebih dahulu');
        router.push({ path: '/login' });
      }
      else {
        // alert('ERROR1');
        next();
      }
    }
    else {
      // alert('ERROR3');
      next({ path: '/login' })
    }


    return true;
  }
  else {
    next()
  }
});

export default router;

