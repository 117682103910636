import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import * as VueGoogleMaps from "vue2-google-maps" // Import package
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
 
// Global registration
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
// import * as VueCharts from "hchs-vue-charts" // Import package

// Vue.use(VueCharts);
library.add(faEdit)
Vue.component('font-awesome-icon', FontAwesomeIcon)



Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCYMJmreyBKqPOve2NUB41CHz4XK4dpFjc",
    libraries: "places"
  }
});

// socket io
// import * as io from "socket.io-client";
// import VueSocketIO from "vue-socket.io";

// Vue.use(
//   new VueSocketIO({
//     debug: true,
//     connection: 'http://localhost:3001', // options object is Optional
//   })
// );

// Vue.config.productionTip = false;

import VueNativeNotification from 'vue-native-notification'
 
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

Vue.filter('tambahindex', function(value) {
  return +value + 1;
});

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
